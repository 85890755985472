<template>
  <QRReader
      v-model:show="show"
      @onRead="onRead"
  />
  <div>
    <van-cell-group title="物料" inset>
      <van-cell>
        <van-field
            v-model="packageCode"
            name="packageCode"
            label="箱号"
            placeholder="请扫描物料包装"
            required
            :rules="[{ required: true, message: '请扫描物料包装' }]"
            @focus="this.focusModel= 'packageCode'"
        ></van-field>
        <van-button round block type="primary" @click="this.show= true,lastShow='packageCode'" style="height: 5.33333vw">
          扫描二维码
        </van-button>
      </van-cell>

      <van-field
          v-model="this.$data['material.name']"
          name="material.name"
          label="物料名称"
          placeholder="物料名称"
          :readonly=true
      ></van-field>
      <van-field
          v-model="this.$data['material.qty']"
          name="material.qty"
          label="原箱内数量"
          placeholder="原箱内数量"
          :readonly=true
      ></van-field>
      <van-field
          v-model="this.$data['supplierName']"
          name="supplierName"
          label="供应商"
          placeholder="供应商"
          :readonly=true
      ></van-field>
    </van-cell-group>

    <van-cell-group title="包装" inset>
      <Picker
          name="packID"
          label="选择新包装"
          placeholder="请选择包装"
          displayField="pack.name"
          valueField="pack.id"
          :required=true
          :readonly = this.packChange
          v-model:value="this.$data['pack.id']"
          :defConfig="{
                    portName:'fr.information.MaterialPackRel.getMaterialPackRels',
                    data:{'material.id':this.$data['material.id']}
                    }"
          :rules="[{ required: true, message: '请选择包装' }]"
          :canSearch=true
          @onConfirm="packConfirm"
          ref="pack"
      />

      <van-cell>
        <van-field
            v-model="newPackageCode"
            name="newPackageCode"
            label="箱号"
            :readonly = this.packChange

            placeholder="请扫描新包装"
            @focus="this.focusModel= 'newPackageCode'"
        ></van-field>
        <van-button round block type="primary" @click="this.show= true,lastShow='newPackageCode'" style="height: 5.33333vw">
        扫描二维码
        </van-button>
      </van-cell>

      <van-field
          v-if="this.$data['pack.id']!==''"
          v-model="this.$data['pack.qty']"
          name="packQty"
          label="包装容纳数量"
          placeholder="包装容纳数量"
          :readonly=true
      ></van-field>
      <van-field
          v-if="this.$data['restQty']!==''"
          v-model="this.$data['restQty']"
          name="restQty"
          label="可放数量"
          placeholder="可放数量"
          :readonly=true
      ></van-field>
      <van-field
          v-model="generatePackQty"
          name="generatePackQty"
          label="翻包物料数量"
          required
          :rules="[{ required: true, message: '请填写数量' }]"
      />
    </van-cell-group>

    <div style="margin: 4.26667vw;">
      <van-row  style="margin-top: 4.26667vw" >
        <van-col span="10">
          <van-button round block type="primary" @click="addWaitGeneratePack">继续扫描</van-button>

        </van-col>
        <van-col span="3"></van-col>
        <van-col span="10">
          <van-button round block type="primary" @click="generatePack">确认翻包</van-button>

        </van-col>
      </van-row>
    </div>
    <div style="background-color: #fafafa">
      <GridList name="materialGrid" title=""
                :immediate-check=false
                :dataList="waitList" style="margin: 4.26667vw;" ref="grid" port-name="fr.order.StockInDetail.getStockInDetails"
                :request-data="{'stockIn.id':1}">
        <ListCard
            v-for="item in waitList"
            :key="item.id"
            :id="item.id"
        >
          <div class="item">
            <div class="fs12">
              <div class="tags fs12">{{'箱号：' + item['packCode']}}</div>
              <div class="tags fs12">{{'物料名称：' + item['materialName']}}</div>
              <div class="tags fs12">{{'翻包数量：' + item['qty']}}</div>
              <div class="tags fs12">{{'翻前包装：' + item['beforePack']}}</div>
              <div class="tags fs12">{{'翻后包装：' + item['afterPack']}}</div>

            </div>
          </div>

        </ListCard>
      </GridList>
    </div>

  </div>

</template>

<script>
import Picker from "../../components/Picker";
import {Toast} from "vant";
import GridList from "@/components/GridList.vue";
import ListCard from "@/components/ListCard.vue";
import QRReader from "@/components/QRReader.vue";

export default {
  name: "pdaRummage",
  components: {Picker,GridList,ListCard,QRReader},
  created(){
    this.getScanCode();
  },
  data() {
    return {
      show:false,
      lastShow:"",
      focusModel:'',
      generatePackQty: '',
      packageCode:'',
      newPackageCode:"",
      materialPackRelID: '',
      supplierName: '',
      packChange: false,
      'material.name': '',
      'material.id': '',
      'material.code':'',
      materialAreaCode:'',

      'wareHouseMaterial.id': '',
      'material.qty': '',
      'material.pack': '',

      'pack.name': '',
      'pack.qty': '',
      'restQty':'',
      'pack.id': '',
      'packQty': '',
      'supplier.id': '',
      'materialCode': '',
      waitList:[],
      waitObject:{
        materialId:"",
        materialName:"",
        qty:0,
        beforePack:"",
        afterPack:"",
        packCode:"",

      }
    };
  },
  methods: {
    onRead(text) {
      var me=this;
      if(this.lastShow ==='packageCode') {
        me.packageCode = text;
        var option1 = {
          portName: 'fr.warehouse.WareHouseMaterial.getWMByPackageCodes',
          data: {'packCodeList': me.packageCode.split('5as1d51as561adasdasd6a51')},
          needLoading: true,
          successCallback: function (data) {
            var entity = data.entities[0]

            if(entity['wareHouseMaterialState.code'] !==  "ON_THE_SHELF"){
              me.$dialog.alert({
                title: '',
                message: '当前正在翻包物料为'+entity['material.name']+'不是上架状态！',
              })
              return;
            }
            if(me.$data['material.code'] !== '' && me.$data['material.code'] !== entity['material.code']){
              me.$dialog.alert({
                title: '',
                message: '当前正在翻包物料为'+me.$data['material.name']+'如需翻包其他的物料请返回重新翻包',
              })
              return;
            }
            if(me.$data['materialAreaCode'] !== '' && me.$data['materialAreaCode'] !== entity['wareHouseArea.code']){
              me.$dialog.alert({
                title: '',
                message: '当前正在翻包物料库区编码为'+me.$data['materialAreaCode']+'如需翻包其他的库区物料请返回重新翻包',
              })
              return;
            }
            me.$data['wareHouseMaterial.id'] = entity['id']
            me.$data['material.code'] = entity['material.code']
            me.$data['material.id'] = entity['material.id']
            me.$data['material.name'] = entity['material.name']
            me.$data['material.qty'] = entity['qty']
            me.$data['material.pack'] = entity['pack.name']
            me.$data['supplierName'] = entity['material.supplier.cnName']
            me.materialAreaCode = entity['wareHouseArea.code']
          }
        }
        this.$sapi.callPort(option1);
      }else if(this.lastShow ==='newPackageCode') {
        me.newPackageCode = text;
        if (me.$data['material.id'] === '') {
          Toast('请先选择物料');
          me.newPackageCode = ''
          return;
        }
        if (me.packChange) {
          me.$dialog.alert({
            title: '',
            message: '当前已确定翻包包装为' + me.$data['pack.name'] + '如需翻包其他的包装请退出重新翻包',
          })
          me.newPackageCode = ''
          return;
        }
        var option2 = {
          portName: 'fr.warehouse.WareHouseMaterial.getWMByPackageCodes',
          data: {'packCodeList': me.newPackageCode.split('5as1d51as561adasdasd6a51'), newRummage: "yes"},
          needLoading: true,
          successCallback: function (data) {
            var entity = data.entities[0]

            if (me.$data['material.code'] !== entity['material.code']) {
              Toast('如扫描新箱号,请保证物料与待翻包物料一致!');
              me.newPackageCode = ''
              return;
            } else if (me.$data['materialAreaCode'] !== entity['wareHouseArea.code']) {
              Toast('请保证物料与待翻包物料【库区】一致!' + '当前正在翻包物料库区编码为' + me.$data['materialAreaCode'] + "当前扫描库区" + entity['wareHouseArea.code']);
              me.newPackageCode = ''
              return;
            } else {
              me.packChange = true
              me.materialPackRelID = entity['packRelId']
              me.$data['pack.qty'] = entity['packRelQty']
              me.$data['pack.id'] = entity['pack.id']
              me.$data['pack.name'] = entity['pack.name']

              me.$data['restQty'] = entity['packRelQty'] - entity['qty']

            }
          }
        }
        this.$sapi.callPort(option2);
      }
    },

    packConfirm: function (value) {
      this.materialPackRelID = value.id;
      this.$data['pack.id'] = value['pack.id'];
      this.$data['pack.name'] = value['pack.name']
      this.$data['pack.qty'] = value['qty']
      if(this.$data['restQty'] === ''){
        this.$data['restQty'] = value['qty']
      }
     /* this.$data['packChange'] = true*/
    },
    getScanCode: function () {
      if (!window.Capacitor) {
        return;
      }
      var me = this;
      Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
        var barcode = data.barcode;

        switch (me.focusModel) {
          case 'packageCode' :
            me.packageCode = barcode;
            var option1 = {
              portName: 'fr.warehouse.WareHouseMaterial.getWMByPackageCodes',
              data: {'packCodeList': me.packageCode.split('5as1d51as561adasdasd6a51')},
              needLoading: true,
              successCallback: function (data) {
                var entity = data.entities[0]

                if(entity['wareHouseMaterialState.code'] !==  "ON_THE_SHELF"){
                  me.$dialog.alert({
                    title: '',
                    message: '当前正在翻包物料为'+entity['material.name']+'不是上架状态！',
                  })
                  return;
                }
                if(me.$data['material.code'] !== '' && me.$data['material.code'] !== entity['material.code']){
                  me.$dialog.alert({
                    title: '',
                    message: '当前正在翻包物料为'+me.$data['material.name']+'如需翻包其他的物料请返回重新翻包',
                  })
                  return;
                }
                if(me.$data['materialAreaCode'] !== '' && me.$data['materialAreaCode'] !== entity['wareHouseArea.code']){
                  me.$dialog.alert({
                    title: '',
                    message: '当前正在翻包物料库区编码为'+me.$data['materialAreaCode']+'如需翻包其他的库区物料请返回重新翻包',
                  })
                  return;
                }
                me.$data['wareHouseMaterial.id'] = entity['id']
                me.$data['material.code'] = entity['material.code']
                me.$data['material.id'] = entity['material.id']
                me.$data['material.name'] = entity['material.name']
                me.$data['material.qty'] = entity['qty']
                me.$data['material.pack'] = entity['pack.name']
                me.$data['supplierName'] = entity['material.supplier.cnName']
                me.materialAreaCode = entity['wareHouseArea.code']
              }
            }
            this.$sapi.callPort(option1);
            break;
          case 'newPackageCode' :
            me.newPackageCode = barcode;
            if(me.$data['material.id'] === ''){
              Toast('请先选择物料');
              me.newPackageCode=''
              return;
            }
            if(me.packChange){
              me.$dialog.alert({
                title: '',
                message: '当前已确定翻包包装为'+me.$data['pack.name']+'如需翻包其他的包装请退出重新翻包',
              })
              me.newPackageCode=''
              return;
            }
            var option2 = {
              portName: 'fr.warehouse.WareHouseMaterial.getWMByPackageCodes',
              data: {'packCodeList': me.newPackageCode.split('5as1d51as561adasdasd6a51'),newRummage:"yes"},
              needLoading: true,
              successCallback: function (data) {
                var entity = data.entities[0]

                if(me.$data['material.code'] !== entity['material.code']){
                      Toast('如扫描新箱号,请保证物料与待翻包物料一致!');
                      me.newPackageCode=''
                      return;
                }else if(me.$data['materialAreaCode'] !== entity['wareHouseArea.code']){
                    Toast('请保证物料与待翻包物料【库区】一致!'+'当前正在翻包物料库区编码为'+me.$data['materialAreaCode']+"当前扫描库区"+entity['wareHouseArea.code']);
                    me.newPackageCode=''
                    return;
                }
                else {
                    me.packChange = true
                    me.materialPackRelID = entity['packRelId']
                    me.$data['pack.qty'] = entity['packRelQty']
                    me.$data['pack.id'] = entity['pack.id']
                    me.$data['pack.name'] = entity['pack.name']

                    me.$data['restQty'] = entity['packRelQty'] -  entity['qty']

                }
              }
            }
            this.$sapi.callPort(option2);
            break;
        }

      })
    },
    generatePack: function () {

      if (this.$data['pack.id'] === '') {
        Toast('请选择包装');
        return;
      }
      var me = this;
      var option = {
        portName: 'fr.warehouse.WareHouseMaterial.addSeparatePackMTO',
        data: {'materialPackRelID': this.materialPackRelID, 'newPackageCode' :this.newPackageCode,  'dataList': this.waitList},
        needLoading: true,
        successCallback: function (data) {
          me.restQty=0
          me.$router.replace("/")
          me.waitList=[]
          me.$data['pack.id']=''
          me.$dialog.alert({
            title: '',
            message: '翻包完成',
          })
        },
        };
      this.$sapi.callPort(option);
    },

    addWaitGeneratePack: function () {
      if (this.$data['material.id'] === '') {
        Toast('请选择物料');
        return;
      }

      if (this.$data['pack.id'] === '') {
        Toast('请选择包装');
        return;
      }
      if (this.generatePackQty === '') {
        Toast('请输入包装数量')
        return;
      }
      if (this.generatePackQty < 0) {
        Toast('包装数量不能小于0');
        return;
      }

      if (this.generatePackQty - this.restQty > 0) {
        Toast('翻包数量不能大于可放数量'+this.generatePackQty+","+this.restQty);
        return;
      }


      let newList = {materialName:this.$data['material.name'] ,materialId:this.$data['wareHouseMaterial.id'],
        qty:this.$data.generatePackQty,beforePack:this.$data['material.pack'],afterPack:this.$data['pack.name'],packCode:this.$data['packageCode']}
      this.$data.waitList.push(newList)
      this.restQty = this.restQty - this.generatePackQty
      this.$data['material.name']=''
      this.$data['material.id']=''
      this.$data['wareHouseMaterial.id']=''
      this.$data['generatePackQty']=''
      this.$data['material.pack']=''
      this.$data['packageCode']=''
      this.$data['material.qty']=''
      this.$data['supplierName']=''

    },
    supplierConfirm(value) {
      this.data['supplier.id'] = value['supplier.id'];
    },
    onPickMaterialConfirm(value) {
      var me = this;
      me.pickOrderOutStockId = value.pickOrderOutStockId;
      var option = {
        portName: 'fr.order.out.PickOrder.outStockInfo',
        data: {'pickOrderOutStockId': me.pickOrderOutStockId},
        needLoading: true,
        successCallback: function (data) {
          me.info = data.data.info;
          //捡配完成
          me.dataList1 = data.data.commuDicGeneratedList;
          //未捡配
          me.dataList = data.data.commuDicNotGeneratedList;
        }
      };
      this.$sapi.callPort(option);
    }
  }
}
</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .content {
  flex: 1;
}

.item .time {
  color: #666;
}
</style>
